// ProfileHeader
@import "variable.global";
.profile_update_header {
  padding-block: 0px !important;

  @media (min-width: 200px) and (max-width: 500px) {
    padding-block: 15px !important;
  }
}

.profile_header_images {
  @media (min-width: 200px) and (max-width: 500px) {
    max-width: 250px !important;
    max-height: 250px !important;
    height: 100% !important;
    width: 100% !important;
  }
}

//manage account header
@mixin profileHeadings($maxWidth: 300px) {
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (min-width: 500px) and (max-width: 850px) {
    max-width: $maxWidth;
  }

  @media (min-width: 200px) and (max-width: 500px) {
    max-width: $maxWidth;
  }
}
.profile_headings {
  @include profileHeadings(250px);
}

.profile_headings_personal {
  @include profileHeadings(250px); // Different max-width value
}

.no_profile_icon_wrapper {
  border:1px solid #33333357 ;
  @media (min-width: 200px) and (max-width: 500px) {
    max-width: 250px;
    width: 250px !important;
  }
  @media (min-width: 200px) and (max-width: 350px) {
    width: 250px !important;
  }
}
.dropdown_img{
  border:1px solid #33333357 ;
}
.theme__div{
  .dropdown_img{
  border: 1px solid #ffffff57;
}
}
.theme__div{
  .no_profile_icon_wrapper{
  border: 1px solid #ffffff57;
}
}
.proflie_header_image_wrapper {
  @media (min-width: 500px) and (max-width: 850px) {
    padding-right: 40px !important;
  }
  @media (min-width: 850px) {
    padding-right: 100px !important;
  }
}
//Overview
.change_password_btn_wrapper {
  margin-left: 12px !important;
  @media (max-width: 350px) {
    margin-left: 0px !important;
  }
}

//selected plan for verification
.selected_plan_card {
  width: 100% !important;
  @media (min-width: 200px) and (max-width: 500px) {
    width: 90% !important;
  }
}
.selected_plan_free_trial_skeleton {
  width: 100% !important;
  @media (min-width: 200px) and (max-width: 500px) {
    width: 100% !important;
  }
}

// image preview in react quill
.image-resize img {
  max-width: 250px !important;
  max-height: 250px !important;
  object-fit: contain !important;
}

//email verification home screen
.tablet_font {
  @media (min-width: 500px) and (max-width: 850px) {
    font-size: 20px !important;
  }
}
//novo wrapper class
.nc-layout-wrapper {
  @media (min-width: 200px) and (max-width: 500px) {
    width: 330px !important;
    margin: auto !important;
  }
}
.general-search-right-section {
  @media (min-width: 200px) and (max-width: 1200px) {
    margin-top: 30px !important;
  }
}

.modal-xl.custom-modal-size {
  @media (min-width: 1200px) {
    --bs-modal-width: 85vw !important;
  }
}
.priority__color{
  color: #3C3C3C !important
}
.bg-archive{
  background-color: #9F70AC !important;
}
//darkmode classes

.layout__theme__div {
  background-color: $background-color-theme;
  color: $text-color-white;
}
.theme__div {
  background-color: $modal-background-color-theme;
  color: $text-color-white;
}
.modal__bg{
  background-color: $modal-background-color-theme;
}

.dark__header__div {
  background-color: $background-color-theme;
  color: $text-color-white;
}
.dark__div {
  background-color: $background-color-dark;
  color: $text-color-white;
}
.layout__dark__div {
  background-color: $background-color-dark;
  color: $text-color-white;
}

.white__text {
  color: $text-color-white;
}
.dark__text {
  color: $background-color-dark-phn;
}

.red__text {
  color: $text-color-red;
}

.dark__theme__input__no__color {
  background-color: $modal-background-color-theme;
}
.dark__theme__input {
  background-color: $modal-background-color-theme;
  color: $text-color-white;

  // border: 1px solid $text-color-red;

  // &:focus {
  //   border-color: lighten($text-color-red, 20%);
  //   outline: none;
  // }
}

.card__theme__dark {
  background-color: $background-color-dark-card;
  color: $text-color-white;
  // border: 2px solid $background-color-theme;
}

.no-arrow::after {
  display: none !important;
}
.close {
  color: #a1a5b7 !important;
}

.dark__search__left__sec {
  background-color: $modal-background-color-theme;
  color: $text-color-white;
  border: 1px solid $text-color-white;
}
.active {
  background-color: #007bff;
  color: white;
}

.active .menu-icon {
  color: white;
}

.react_date_picker_dark .react-date-picker__inputGroup {
  background-color: $background-color-theme;
  color: $text-color-white;
}
.react_date_picker_dark .react-date-picker__calendar .react-calendar {
  background-color: $background-color-theme;
  color: $text-color-white;
}
.react_date_picker_dark .react-calendar__tile {
  color: $text-color-white;
}
.react_date_picker_dark .react-calendar button {
  color: $text-color-white;
}
.react_date_picker_dark .react-calendar__navigation button:disabled {
  color: $text-color-white;
  background-color: $background-color-dark;
}
.react_date_picker_dark .react-calendar__tile:disabled {
  background-color: #b29e9e;
}
.react_date_picker_dark .react-daterange-picker__calendar .react-calendar {
  background-color: $background-color-theme;
  color: $text-color-white;
}
.react_date_picker_dark .react-calendar__tile:enabled:hover {
  background-color: $background-color-dark;
  color: $text-color-white;
}
.react_date_picker_dark .react-calendar__tile:enabled:focus {
  background-color: $background-color-dark;
  color: $text-color-white;
}
//phn input light
.react-tel-input-light {
  background-color: $text-color-white !important; /* Replace with white text color */
  color: $background-color-dark-phn !important; /* Replace with dark background color */
}

.react-tel-input-light input {
  background-color: $text-color-white !important; /* Replace with white text color */
  color: $background-color-dark-phn !important; /* Replace with dark background color */
  border: 1px solid $text-color-white !important; /* Keep border color if desired, or adjust as needed */
}

.react-tel-input-light .country-list {
  background-color: $text-color-white !important; /* Replace with white text color */
  color: $background-color-dark-phn !important; /* Replace with dark background color */
}

.react-tel-input-light .country-list .country {
  background-color: $text-color-white !important; /* Replace with white text color */
  color: $background-color-dark-phn !important; /* Replace with dark background color */
}

.react-tel-input-light .country-list .country:hover {
  background-color: $text-color-white !important; /* Keep hover background color if desired, or adjust as needed */
}

.react-tel-input-light .selected-flag {
  background-color: $text-color-white !important; /* Replace with white text color */
  color: $background-color-dark-phn !important; /* Replace with dark background color */
}

.react-tel-input-light .selected-flag:hover {
  background-color: $text-color-white !important; /* Keep hover background color if desired, or adjust as needed */
}

.react-tel-input-light .country-list .country.highlight {
  background-color: $text-color-white !important; /* Keep highlight background color if desired, or adjust as needed */
  color: $background-color-dark-phn !important; /* Replace with white text color */
}
//phone input
.react-tel-input-dark-dark {
  background-color: $background-color-theme !important;
  color: $text-color-white !important;
}

.react-tel-input-dark input {
  background-color: $background-color-light-phn !important;
  color: $text-color-white !important;
  border: 1px solid $text-color-white !important;
}

.react-tel-input-dark .country-list {
  background-color: $background-color-theme !important;
  color: $text-color-white !important;
}

.react-tel-input-dark .country-list .country {
  background-color: $background-color-theme !important;
  color: $text-color-white !important;
}

.react-tel-input-dark .country-list .country:hover {
  background-color: $background-color-light-phn !important;
}

.react-tel-input-dark .selected-flag {
  background-color: $background-color-light-phn !important;
  color: $text-color-white !important;
}

.react-tel-input-dark .selected-flag:hover {
  background-color: $background-color-light-phn !important;
}

.react-tel-input-dark .country-list .country.highlight {
  background-color: $background-color-light-phn !important;
  color: $text-color-white !important;
}

.react_date_dark .react-datepicker {
  background-color: $background-color-theme !important;
  color: $text-color-white !important;
}
// .nc-notifications-list-item-timestamp{
//   // color:  lightskyblue !important;
// }
.quill > .ql-container > .ql-editor .ql-editor p {
  padding-inline: 1px !important;
}

.ElementsApp input {
  color: $text-color-dark;
}

.ElementsApp,
.ElementsApp .InputElement {
  color: $text-color-dark;
}

.dark-mode-placeholder {
  .quill > .ql-container > .ql-editor.ql-blank::before {
    color: #98C6F0;
  }
  .ql-snow .ql-stroke {
    stroke: lightgray  ;
  }
  .ql-snow .ql-fill {
    fill: lightgray  ;
  }
  .ql-snow .ql-stroke:hover {
    stroke: #06c ;
  }
  .ql-snow .ql-fill:hover {
    fill: #06c ;
  }
  .ql-snow a {
    color: #0A95E9 ;
  }
  .ql-picker-label {
    color: lightgray  ;
  }
  .ql-picker-label:hover {
    color: #06c  ;
  }
  .ql-picker-options {
    background-color: $background-color-theme;
    color: $text-color-white;
  } 
}
.StripeElement {
  color: white !important;
}

.card-column-img {
  background-color: $column-card-img-background;
}

.dark-mode-placeholder {
  .quill > .ql-container > .ql-editor h1,
  .quill > .ql-container > .ql-editor h2 {
    color: #02a0ef;
  }
}

.due_date_color_green{
  color: #1BEE29;
}

.dark-options{
  .bi-three-dots{
    color:rgb(255, 104, 0) !important
  }
  .dropdown-toggle::after{
    color:rgb(255, 104, 0) !important
  }
}

@media (max-width: 576px) {
  .hide-sm{
    display: none;
  }
}
.theme__div{
  .sc-eqUAAy  > span{
    color: #0A95E9 !important;
  }
}
.red_border{
  border: 1px solid red !important;
}
.active_tab{
  color: rgb(0, 158, 247) !important; 
}
.status__box{
  border: 1px solid rgb(201, 201, 201);
  padding: 4px 12px;
  border-radius: 7px;
}
.copyright_text{
  font-size: 75%;
}
.aside-enabled.aside-fixed[data-kt-aside-minimize=on] .copyright_text {
  display: none;
}
.ql-tooltip{
  transform: translateY(10px) translateX(35%) !important;
}
.tags_para{
  color: #0000FF;
}
.card__theme__dark{
  .tags_para{
    color: #C3C720 ;
  }
}
.tag_list_skeleton{
  top: 90%;
  display: flex;
  width: 87%;
}

.page_tag_list{
  min-height: 75px;
  height: 75px;
  overflow: auto;
  width: 100%;
  border-radius: 5px; 
  background-color: rgb(235, 235, 235) !important;
}
.board_tag_list{
  min-height: 55px;
  height: 50px;
  overflow: auto;
  width: 100%;
  border-radius: 5px; 
  background-color: rgb(235, 235, 235) !important;
  width: 58%;
}

.card__theme__dark.page_tag_list {
  background-color: #2C3A4D !important;
  border: 1px solid white !important;
}
.card__theme__dark.board_tag_list {
  background-color: #2C3A4D !important;
  border: 1px solid white !important;
}
.start-90{
  left: 90% !important;
}
.page__key{
  bottom: 10px;
  right: 0;
  font-size: 12px;
}

.dark__theme__input{
  .ql-mention-list-container{
    background-color: #2C3A4D;
  }
}
.add_task_btn{
  background-color: #B8D4E9;
  color: #333333;
}
.react-joyride__tooltip div{
  text-align: start !important;
}
.selected_item_bg_dark{
  background-color: #ffc70063 !important;
}
.selected_item_bg_light{
  background-color: #0887fe2b !important;
}
.tag_btn{
  color: #0D0A0A;
}
.home_view{
  position: absolute;
  top: -4px !important;
  bottom: 0 !important;
}
.board_view{
  position: absolute;
  top: 0;
  bottom: 0;
}
.ql-image{
  padding: 1px 5px !important;
}
.drag_drop_container{
  display:grid
}
.ql-customButtonMention .ql-customButtonLink .ql-customButtonPage  {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}
.dark__theme__input{
  .ql-customButtonMention {
    color: white;
  }
  .ql-customButtonPage {
    color: white;
  }
  .ql-customButtonLink {
    color: white;
  }

}

.ql-customButtonMention::before {
  content: '@'; /* You can replace this with an icon or custom text */
  font-weight: bold;
}

.ql-customButtonLink::before  {
  content: '/'; /* You can replace this with an icon or custom text */
  font-weight: bold;
}

.ql-customButtonPage::before {
  content: '#'; /* You can replace this with an icon or custom text */
  font-weight: bold;
}